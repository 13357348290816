@charset "UTF-8";
.btn-box--white, .btn-outline--white, .btn-outline--white-s, .btn-box--green {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.btn-box--green {
  background-color: #75bf4b !important;
  color: #fff;
  padding: 12px 24px;
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  letter-spacing: 3px;
}
.btn-box--green:hover {
  background-color: #fff;
  color: #000;
}

.btn-box--white, .btn-outline--white, .btn-outline--white-s {
  background-color: #000;
  color: #fff;
  padding: 12px 24px;
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  letter-spacing: 3px;
}
.btn-box--white:hover, .btn-outline--white:hover, .btn-outline--white-s:hover {
  background-color: #fff;
  color: #000;
}

.btn-outline--white, .btn-outline--white-s {
  border: 1px solid #fff;
  background-color: transparent;
}
.btn-outline--white:hover, .btn-outline--white-s:hover {
  background-color: #fff;
  color: #000;
}

.btn-outline--white-s {
  padding: 0;
}
.btn-outline--white-s span {
  background-color: #000;
  z-index: 1;
  padding: 12px 34px;
  cursor: pointer;
}
.btn-outline--white-s span:hover {
  background-color: #fff;
  color: #000;
}
.btn-outline--white-s:hover {
  background-color: #fff;
  color: #000;
}

.main-menu-burger {
  width: 30px;
  height: 20px;
  position: relative;
  background: transparent;
  border: 0;
  outline: none;
  transform: rotate(0deg);
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
}
.main-menu-burger span {
  display: block;
  height: 4px;
  width: 50%;
  position: absolute;
  background: #fff;
  transform: rotate(0deg);
  transition: transform 0.25s ease-in-out;
  opacity: 1;
}
.main-menu-burger span:nth-child(even) {
  left: 50%;
}
.main-menu-burger span:nth-child(odd) {
  left: 0;
}
.main-menu-burger span:nth-child(1),
.main-menu-burger span:nth-child(2) {
  top: 0;
}
.main-menu-burger span:nth-child(3),
.main-menu-burger span:nth-child(4) {
  top: 9px;
}
.main-menu-burger span:nth-child(5),
.main-menu-burger span:nth-child(6) {
  top: 18px;
}
.main-menu-burger.open span:nth-child(1),
.main-menu-burger.open span:nth-child(6) {
  transform: rotate(45deg);
}
.main-menu-burger.open span:nth-child(2),
.main-menu-burger.open span:nth-child(5) {
  transform: rotate(-45deg);
}
.main-menu-burger.open span:nth-child(1) {
  left: 3px;
  top: 3px;
}
.main-menu-burger.open span:nth-child(2) {
  left: calc(50% - 3px);
  top: 3px;
}
.main-menu-burger.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}
.main-menu-burger.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}
.main-menu-burger.open span:nth-child(5) {
  left: 3px;
  top: 12px;
}
.main-menu-burger.open span:nth-child(6) {
  left: calc(50% - 3px);
  top: 12px;
}

@font-face {
  font-family: "greatvibes";
  src: url("/assets/fonts/greatvibes-regular-webfont.woff2") format("woff2"), url("/assets/fonts/greatvibes-regular-webfont.woff") format("woff"), url("/assets/fonts/greatvibes-regular-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
.fa-3d {
  font-size: 90px;
  transform-style: preserve-3d;
  perspective: 1000px;
  animation-name: gongon;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

@keyframes gongon {
  0% {
    transform: rotateY(0deg);
  }
  25% {
    transform: rotateY(40deg);
  }
  50% {
    transform: rotateY(0deg);
  }
  75% {
    transform: rotateY(-40deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
body.api-loading,
body.api-loading * {
  cursor: progress !important;
}

.main-loading-element {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  z-index: 2147483647;
  border-radius: 3px;
  will-change: width, opacity;
  transition: width 500ms ease-out, opacity 500ms linear;
  opacity: 0;
  background-color: #000;
}
.main-loading-element.loading {
  transition: width 500ms linear;
  opacity: 1;
}
.main-loading-element::after, .main-loading-element::before {
  content: " ";
  position: absolute;
  color: #000;
  top: 0;
  height: 3px;
  box-shadow: 1px 0 6px 1px;
  border-radius: 100%;
  animation: pulse 2s ease-out 0s infinite;
}
.main-loading-element::after {
  width: 20px;
  opacity: 0.6;
  right: 0;
  clip: rect(-6px, 30px, 14px, 10px);
}
.main-loading-element::before {
  opacity: 0.6;
  width: 180px;
  right: -80px;
  clip: rect(-6px, 90px, 14px, -6px);
}

@keyframes pulse {
  30% {
    opacity: 0.6;
  }
  60% {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }
}
.project-logo {
  height: 55px;
  margin: 0;
}

.top-project-bar {
  position: relative;
  min-height: 60px;
}
.top-project-bar a {
  position: relative;
  z-index: 10;
}
.top-project-bar > span {
  max-width: 350px;
}
@media only screen and (min-width: 960px) {
  .top-project-bar h1 {
    position: absolute;
    width: 100%;
  }
}

.news p a {
  color: #75bf4b;
  text-decoration: underline;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  font-size: 62.5%;
}

iframe {
  width: 100%;
  height: 100%;
}

body {
  min-height: 100%;
  font-family: "Libre Baskerville", serif;
  font-size: 1.6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #000;
  color: #fff;
}
body.no-scroll {
  overflow: hidden;
}
body p {
  font-family: "Libre Baskerville", serif;
}
body .qoute-text {
  font-family: "Nunito", sans-serif !important;
}

app-root {
  display: flex;
  flex-direction: column;
  position: relative;
}
app-root .site-content {
  position: relative;
}
app-root router-outlet ~ * {
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-weight: normal;
}

h1 {
  font-weight: bold;
}

h2 {
  font-size: 3rem;
  font-weight: bold;
}

h3 {
  font-size: 2.3rem;
  font-weight: bold;
}

h4 {
  font-size: 1.8rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

p {
  line-height: 24px;
  text-align: justify;
}

button {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: none;
}

.container {
  width: 100%;
  max-width: 1105px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.quote.bold {
  font-weight: bold;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pt-5 {
  padding-top: 5rem;
}

.py-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-5 {
  padding-right: 5rem;
  padding-left: 5rem;
}

.hero {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  position: relative;
}
@media only screen and (min-width: 960px) {
  .hero {
    min-height: 750px;
  }
}
.hero .overlay {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.hero p {
  font-size: 1.6rem;
}
.hero blockquote::before {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

img.gear {
  width: 100px !important;
}

.hr-top-right::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  height: 1px;
  background-color: #fff;
}

.hr-top-left::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 1px;
  background-color: #fff;
}

.quote {
  padding: 30px;
  line-height: 32px;
  font-size: 1.8rem;
  text-align: center;
  position: relative;
}
.quote:not(.no-icon)::after {
  font-size: 15rem;
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translateX(-65%);
}
@media only screen and (min-width: 960px) {
  .quote {
    padding: 30px 70px;
  }
}

.cta {
  position: relative;
  padding-top: 6rem;
}
.cta > div {
  margin: auto;
}
.cta p {
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.4;
}
.cta hr {
  width: 25%;
}

.section-title {
  text-align: center;
}
.section-title h1 {
  font-size: 5rem;
}
.section-title label {
  display: block;
  font-size: 1.4rem;
}

.history-body > div:first-child {
  order: 2;
  margin-top: 100px;
}
@media only screen and (min-width: 600px) {
  .history-body > div:first-child {
    margin-top: 0;
    order: 0;
  }
}
.history-body p {
  line-height: 24px;
}
.history-body .img-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 320px;
  position: relative;
}
@media only screen and (min-width: 600px) {
  .history-body .img-wrapper {
    height: 100%;
  }
}
.history-body .img-wrapper::before {
  content: "";
  width: calc(100% + 30px);
  height: 200px;
  background-color: #fff;
  position: absolute;
  bottom: -85px;
  left: -60px;
  z-index: -1;
}

.projects-list .history-body:nth-child(even) > div:first-child {
  order: 2;
}
.projects-list .history-body + .history-body {
  margin-top: 70px;
}
@media only screen and (min-width: 600px) {
  .projects-list .history-body + .history-body {
    margin-top: 150px;
    margin-bottom: 150px;
  }
}
.projects-list .history-body p + a {
  margin-top: 40px;
}
.projects-list .history-body p + label {
  margin-top: 30px;
  margin-bottom: 30px;
}
.projects-list .history-body label {
  font-size: 2.4rem;
  font-family: "Nunito", sans-serif;
}
.projects-list .history-body > div:first-child {
  display: flex;
  flex-direction: column;
}
.projects-list .history-body > div:first-child a {
  align-self: flex-start;
}

.border-bottom-right {
  border-bottom: 1px solid #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 60px);
}

.pos-relative {
  position: relative;
}

.qoute-text {
  text-transform: uppercase;
  text-align: center;
  font-size: 2.8rem;
  line-height: 1.4;
}

.qoute {
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.8;
}

.special-letter-wrapper {
  position: relative;
  display: inline-block;
}

.special-letter {
  font-size: 2em;
  position: absolute;
  top: 50%;
}
.special-letter.left {
  left: 0%;
  transform: translate(-110%, -60%);
}
.special-letter.right {
  transform: translate(110%, -75%);
  right: 0%;
}

h1.special-letter-wrapper,
h2.special-letter-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
h1.special-letter-wrapper .special-letter,
h2.special-letter-wrapper .special-letter {
  position: static;
  transform: translate(0, -8%);
}

@media only screen and (min-width: 960px) {
  h1.special-letter-wrapper .special-letter {
    transform: translate(0, -20%);
  }
}

.scroll-to-top {
  height: 50px;
  width: 50px;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  position: fixed;
  left: 12px;
  bottom: 12px;
  border: 2px solid #fff;
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out, border-radius 0.3s ease-in-out;
  cursor: pointer;
  padding: 5px;
}
.scroll-to-top svg {
  height: 100%;
  width: 100%;
}
.scroll-to-top:hover {
  opacity: 1;
  border-radius: 50%;
}
.scroll-to-top.show {
  display: flex;
}
.scroll-to-top .material-icons {
  font-size: 36px;
}